import {BasicInfo} from "../types/forms/basic_info";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


const initialState: BasicInfo = {
    contact: {
        name: "",
        url: "",
        email: "",
    },
    openapi: {openapi: "3.0.3"},
    info: {
        version: "1.0.0",
        title: "",
        description: "",
        termsOfService: "",
    },
    license: {
        name: "",
        url: ""
    }
}

export const basicInfoSlice = createSlice({
    name: "basic_info",
    initialState: initialState,
    reducers: {
        update: (state, action: PayloadAction<BasicInfo>) => {
            state.info = Object.assign(state.info, action.payload.info)
            state.openapi = Object.assign(state.openapi, action.payload.openapi)
            state.contact = Object.assign(state.contact, action.payload.contact)
            state.license = Object.assign(state.license, action.payload.license)
        }
    }
})

export const {
    update: updateBasicInfo
} = basicInfoSlice.actions