import React from "react";
import {useFormikContext} from "formik";
import flatten from "flat";
import {EuiForm} from "@elastic/eui";

export const EuiFormik: React.FC = ({children}) => {
    const {touched, errors} = useFormikContext()

    const f: Record<string, string> = flatten(errors);
    const t: Record<string, string> = flatten(touched);
    let errorsArr: string[] = [];
    if (Object.keys(touched).length > 0) {
        errorsArr = Object.keys(f).filter((key) => Object.prototype.hasOwnProperty.call(t, key)).map((key) => f[key])
    }


    return (
        <EuiForm isInvalid={errorsArr.length > 0} error={errorsArr}>
            {children}
        </EuiForm>
    )
}