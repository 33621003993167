import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react'
import {persistConfig, rootStore} from "./reducers";
import {App} from "./App";
import {getStoredState, persistStore} from "redux-persist";
import {REHYDRATE} from "redux-persist/es/constants";

/*const store = createStore(
    rootReducer,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)*/

const persistor = persistStore(rootStore)
window.addEventListener('storage', async (e) => {
    if (e.oldValue === e.newValue) {
        return
    }
    const state = await getStoredState(persistConfig)
    rootStore.dispatch({
        type: REHYDRATE,
        key: persistConfig.key,
        payload: state,
    })
}, false)


ReactDOM.render(
    <React.StrictMode>
        <Provider store={rootStore}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);