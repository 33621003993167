import React from 'react';

import '@elastic/eui/dist/eui_theme_light.css';

import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiTitle,
    EuiButton,

} from '@elastic/eui';
import {FullOpenAPI} from "./types/openapi/full_openapi";
import {RootState} from "./reducers";
import {useSelector} from 'react-redux';
import {BasicEditor} from "./components/BasicEditor";
import {MainAPIPage} from "./components/MainAPIPage";
import {ServerForm} from "./components/ServerForm";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import {RedocPreview} from "./components/RedocPreview";
import {PathForm} from "./components/PathForm";
import {BodyForm} from "./components/BodyForm";

function DownloadJSON(obj: FullOpenAPI) {
    const myURL = window.URL || window.webkitURL
    const blob = new Blob([JSON.stringify(obj)], {type: "application/json"})
    const jsonURL = myURL.createObjectURL(blob);
    const tempLink = document.createElement("a")
    tempLink.href = jsonURL
    tempLink.setAttribute("download", "openapi.json")
    tempLink.click()
}

export const App: React.FC = () => {
    const fullAPI: FullOpenAPI = useSelector((state: RootState) => {
        return {
            openapi: state.BasicInfo.openapi.openapi,
            info: {
                ...state.BasicInfo.info,
                contact: state.BasicInfo.contact,
                license: state.BasicInfo.license,
            },
            servers: state.Servers,
            paths: state.Paths,
            tags: state.Tags,
        }
    })

    return (
        <EuiPage>
            <EuiPageBody component="div">
                <EuiPageHeader>
                    <EuiPageHeaderSection>
                        <EuiTitle size="l">
                            <h1>OpenAPI Editor</h1>
                        </EuiTitle>
                    </EuiPageHeaderSection>
                    <EuiPageHeaderSection>
                        <EuiButton
                            fill
                            onClick={() => DownloadJSON(fullAPI)}
                            iconType="download"
                            color="secondary"
                        >
                            Download
                        </EuiButton>
                    </EuiPageHeaderSection>
                </EuiPageHeader>
                <EuiPageContent>
                    <EuiPageContentBody>
                        <EuiPageContentBody>
                            <Router>
                                <Switch>
                                    <Route path="/" exact={true}>
                                        <BasicEditor/>
                                    </Route>
                                    <Route path="/dashboard" exact={true}>
                                        <MainAPIPage/>
                                    </Route>

                                    <Route path="/server_form" exact={true}>
                                        <ServerForm/>
                                    </Route>
                                    <Route path="/path_form" exact={true}>
                                        <PathForm/>
                                    </Route>
                                    <Route path="/preview" exact={true}>
                                        <RedocPreview spec={fullAPI}/>
                                    </Route>
                                    <Route path="/body_form" exact={true}>
                                        <BodyForm/>
                                    </Route>
                                </Switch>
                            </Router>
                        </EuiPageContentBody>
                    </EuiPageContentBody>
                </EuiPageContent>
            </EuiPageBody>
        </EuiPage>
    );
}

