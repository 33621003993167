import React from "react";
import {RedocStandalone} from 'redoc';
import {FullOpenAPI} from "../types/openapi/full_openapi";

interface RedocPreviewProps {
    spec: FullOpenAPI;
}

export const RedocPreview: React.FC<RedocPreviewProps> = ({spec}) => {
    console.log(spec)
    return (
        <div>
            <RedocStandalone spec={spec}/>
        </div>
    )
}