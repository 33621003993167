import {Tag} from "../types/openapi/tag";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: Tag[] = []


export const tagsSlice = createSlice({
    name: "path",
    initialState: initialState,
    reducers: {
        add: (state, action: PayloadAction<Tag>) => {
            state.push(action.payload)
        },
        updateAll: (state, action: PayloadAction<Tag[]>) => {
            return action.payload
        },
        delete: (state, action: PayloadAction<Tag>) => {
            const deleteIndex = state.findIndex(tag => tag.name === action.payload.name)
            if (deleteIndex !== -1) {
                state.splice(deleteIndex, 1)
            }
        }
    }
})

export const {
    add: addTag,
    updateAll: updateAllTags,
    delete: deleteTag,
} = tagsSlice.actions
