import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import {
    EuiText,
    EuiTitle,
    EuiButton,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiButtonIcon,
    EuiFormRow,
    EuiFlexItem,
    EuiSpacer,
    EuiPanel,
    EuiLink,
    EuiCollapsibleNavGroup,
    EuiBadgeGroup,
    EuiComboBox,
    EuiBadge
} from '@elastic/eui';
import {RootState} from "../reducers";
import {FullOpenAPI} from "../types/openapi/full_openapi";
import {Link, useHistory} from "react-router-dom";
import {RedocPreview} from "./RedocPreview";
import {addTag, deleteTag} from '../reducers/tags';

export const MainAPIPage: React.FC = () => {
    const fullAPI: FullOpenAPI = useSelector((state: RootState) => {
        return {
            openapi: state.BasicInfo.openapi.openapi,
            info: {
                ...state.BasicInfo.info,
                contact: state.BasicInfo.contact,
                license: state.BasicInfo.license,
            },
            servers: state.Servers,
            paths: state.Paths,
            tags: state.Tags,
        }
    })

    const history = useHistory()
    const dispatch = useDispatch()

    return (
        <div>
            <EuiTitle>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                        <h2>{fullAPI.info.title}</h2>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <Link to="/">
                            <EuiButton size="s">Back to
                                Edit
                            </EuiButton>
                        </Link>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiTitle>
            <EuiText size="xs">
                <p>Version: {fullAPI.info.version} (OpenAPI {fullAPI.openapi}) {fullAPI.info.termsOfService &&
                <EuiButtonEmpty target="_blank" href={fullAPI.info.termsOfService} size="xs">
                    TOS
                </EuiButtonEmpty>}
                </p>
            </EuiText>
            {fullAPI.info.contact.name &&
            <EuiText size="s">
                <div>Contact Info: {fullAPI.info.contact.name}
                    <EuiButtonIcon target="_blank" href={`mailto:${fullAPI.info.contact.email}`} size="s"
                                   iconType="email"/>
                    <EuiButtonIcon target="_blank" href={fullAPI.info.contact.url} size="s" iconType="link"/>
                </div>
            </EuiText>
            }
            {fullAPI.info.license.name &&
            <EuiText size="s">
                <div>License:
                    <EuiButtonEmpty target="_blank" href={fullAPI.info.license.url} size="xs">
                        {fullAPI.info.license.name}
                    </EuiButtonEmpty>
                </div>
            </EuiText>
            }

            <EuiCollapsibleNavGroup title="Servers" titleSize="s" isCollapsible={true}
                                    initialIsOpen={true} paddingSize="xs" iconSize="xl">
                <EuiFlexGroup direction="column">
                    {fullAPI.servers.map((server) => {
                        return (<EuiFlexItem grow={false} key={server.url}>
                                <EuiPanel onClick={() => {
                                    history.push({
                                        pathname: "/server_form",
                                        state: {...server},
                                    })
                                }}>
                                    <EuiText>
                                        <EuiText>{server.description}</EuiText>
                                        <EuiLink href={server.url} target="_blank"
                                                 onClick={(e) => e.stopPropagation()}
                                                 rel="noopener noreferrer">{server.url}</EuiLink>
                                    </EuiText>
                                </EuiPanel>
                            </EuiFlexItem>
                        )
                    })}
                </EuiFlexGroup>
                <EuiSpacer size="xl"/>

                <Link to="/server_form">
                    <EuiButton>
                        Add Server
                    </EuiButton>
                </Link>
            </EuiCollapsibleNavGroup>

            <EuiCollapsibleNavGroup title="Tags" titleSize="s" isCollapsible={true} initialIsOpen={true}
                                    paddingSize="xs" iconSize="xl">
                <EuiBadgeGroup>
                    {fullAPI.tags.map(t => {
                        return <EuiBadge color="hollow" iconType="cross" iconSide="right" iconOnClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            dispatch(deleteTag(t))
                        }} iconOnClickAriaLabel="Delete">
                            {t.name}
                        </EuiBadge>
                    })}
                </EuiBadgeGroup>
                <EuiSpacer size="s"/>
                <EuiFormRow>
                    <EuiComboBox noSuggestions delimiter="," placeholder="Add tag" selectedOptions={[]}
                                 onCreateOption={searchValue => {
                                     const normalizedSearchValues = searchValue.trim().toLowerCase();
                                     if (!normalizedSearchValues) {
                                         return
                                     }

                                     const flatTags = fullAPI.tags.map(t => t.name)

                                     // TODO: DESCRIPTION + EuiSuperSelect for description view
                                     if (flatTags.findIndex(tag => tag.trim().toLowerCase() === normalizedSearchValues) === -1) {
                                         dispatch(addTag({
                                             name: searchValue
                                         }))
                                     }
                                 }}/>
                </EuiFormRow>

            </EuiCollapsibleNavGroup>


            <EuiCollapsibleNavGroup title="Paths" titleSize="s" isCollapsible={true}
                                    initialIsOpen={true} paddingSize="xs" iconSize="xl">
                <EuiFlexGroup direction="column">
                    {(() => {
                        const elements = []
                        for (const path in fullAPI.paths) {
                            if (!Object.prototype.hasOwnProperty.call(fullAPI.paths, path)) {
                                continue
                            }

                            const p = fullAPI.paths[path]

                            elements.push(
                                <EuiFlexItem grow={false} key={path}>
                                    <EuiPanel onClick={() => {
                                        history.push({
                                            pathname: "/path_form",
                                            state: {route: path, path: p},
                                        })
                                    }}>
                                        <EuiText>
                                            <EuiText>{p.description}</EuiText>
                                            {/*TODO: SERVER URL*/}
                                            <EuiLink href={path} target="_blank"
                                                     onClick={(e) => e.stopPropagation()}
                                                     rel="noopener noreferrer">{path}</EuiLink>
                                        </EuiText>
                                    </EuiPanel>
                                </EuiFlexItem>
                            )
                        }

                        return elements
                    })()}
                </EuiFlexGroup>
                <EuiSpacer size="xl"/>

                <Link to="/path_form">
                    <EuiButton>
                        Add Path
                    </EuiButton>
                </Link>
            </EuiCollapsibleNavGroup>
            <EuiSpacer size="xl"/>
            <EuiSpacer size="xl"/>
            <EuiSpacer size="xl"/>

            <RedocPreview spec={fullAPI}/>
        </div>
    )
}

