import {combineReducers} from "redux";
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {serversSlice} from "./server";
import {Server} from "../types/openapi/server";
import { basicInfoSlice} from "./basic_info";
import {BasicInfo} from "../types/forms/basic_info";
import {pathsSlice} from "./path";
import {Path} from "../types/openapi/path";
import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";
import {Tag} from "../types/openapi/tag";
import {tagsSlice} from "./tags";

export const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}

export interface RootState {
    BasicInfo: BasicInfo,
    Servers: Server[],
    Paths: Record<string, Path>,
    Tags: Tag[]
}

const reducer = persistReducer(persistConfig, combineReducers({
    BasicInfo: basicInfoSlice.reducer,
    Servers: serversSlice.reducer,
    Paths: pathsSlice.reducer,
    Tags: tagsSlice.reducer
}))

export const rootStore = configureStore({
    reducer: reducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    })
})
