import React from "react";
import {
    EuiButton,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
    EuiTitle,
} from '@elastic/eui';
import {useDispatch, useSelector} from "react-redux";
import {Server} from "../types/openapi/server";
import {Link, useHistory, useLocation} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import {RootState} from "../reducers";
import {deleteServer, upsertServer} from "../reducers/server";
import {EuiFormik} from "./myformik/form";
import {EuiFormikInputRow} from "./myformik/input_row";
import {EuiFormikSubmitButton} from "./myformik/button";
import {URLRegexp} from "../utils/validation";

export const ServerForm: React.FC = () => {
    const location = useLocation<Server>()
    const history = useHistory()
    const initialServer = location.state ? location.state : {url: "", description: ""}
    const title = location.state ? "Change server" : "Add server"
    const servers = useSelector((state: RootState) => state.Servers)
    const server_urls = servers.filter((server) => {
        if (!location.state) {
            return true
        }

        return server.url !== location.state?.url
    }).map(s => s.url)

    const schema = Yup.object({
        url: Yup.string().matches(URLRegexp, "Server URL must be a valid URL").required("Server URL is required").notOneOf(server_urls, "Server URL must be unique"),
        description: Yup.string().required("Server description is required"),
    })

    const dispatch = useDispatch();

    const handleSubmit = (server: Server) => {
        dispatch(upsertServer({server: server, initial_server_url: initialServer.url}))
        history.push("/dashboard")
    }


    const handleDeleteServer = (server: Server) => {
        dispatch(deleteServer(server))
        history.push("/dashboard")
    }

    return (
        <Formik initialValues={initialServer} onSubmit={handleSubmit} validationSchema={schema}>
            {({values: server}) =>
                <Form>
                    <EuiFormik>
                        <EuiTitle>
                            <EuiFlexGroup justifyContent="spaceBetween">
                                <EuiFlexItem grow={false}>
                                    <h2>{title}</h2>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <Link to="/dashboard">
                                        <EuiButton size="s">Back to dashboard</EuiButton>
                                    </Link>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiTitle>
                        <EuiSpacer size="s"/>

                        <EuiFormikInputRow label="URL" placeholder="http://localhost:8080" as={EuiFieldText}
                                           name="url"/>
                        <EuiFormikInputRow label="Description" placeholder="Local server" as={EuiFieldText}
                                           name="description"/>
                        <EuiSpacer size="xl"/>

                        <EuiFormikSubmitButton fill type="submit">{title}</EuiFormikSubmitButton>
                        <EuiSpacer size="xxl"/>
                        <EuiButton color="danger" onClick={() => handleDeleteServer(server)}>Delete server</EuiButton>
                    </EuiFormik>
                </Form>
            }
        </Formik>
    )
}
