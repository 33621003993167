import {Path, PathWrapper} from "../types/openapi/path";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: Record<string, Path> = {}

export const pathsSlice = createSlice({
    name: "paths",
    initialState: initialState,
    reducers: {
        delete: (state, action: PayloadAction<PathWrapper>) => {
            delete state[action.payload.route]
        },
        upsert: (state, action: PayloadAction<[PathWrapper, string]>) => {
            // UPDATE
            if (Object.prototype.hasOwnProperty.call(state, action.payload[1])) {
                delete state[action.payload[1]]
            }

            // INSERT
            state[action.payload[0].route] = action.payload[0].path
        }
    }
})

export const {
    delete: deletePath,
    upsert: upsertPath,
} = pathsSlice.actions