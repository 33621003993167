import {Props} from "@elastic/eui/src/components/button/button";
import flatten from "flat";
import {EuiButton} from "@elastic/eui";
import React from "react";
import {useFormikContext} from "formik";


export const EuiFormikSubmitButton: React.FC<Props> = ({...props}) => {
    const {touched, errors} = useFormikContext()

    return (
        <EuiButton
            {...props}
            color={
                (() => {
                    const f: Record<string, string> = flatten(errors);
                    const t: Record<string, string> = flatten(touched);
                    if (Object.keys(touched).length === 0) {
                        return "primary"
                    }
                    return Object.keys(f).filter((key) => Object.prototype.hasOwnProperty.call(t, key)).map((key) => f[key]).length > 0 ? "danger" : "primary"
                })()
            }
        >
            {props.children}
        </EuiButton>
    )

}