import React from "react";
import {Field, FieldAttributes, useField} from "formik";
import {EuiFormRow} from "@elastic/eui";

type EuiFormikInputRowProps =
    { label: string, placeholder: string, as: React.FC }
    & FieldAttributes<{}>

export const EuiFormikInputRow: React.FC<EuiFormikInputRowProps> = ({label, placeholder, as, ...props}) => {
    const [field, meta] = useField<{}>(props)
    return (
        <EuiFormRow label={label}
                    isInvalid={!!meta.error && meta.touched}
                    error={meta.touched && meta.error}
        >
            <Field as={as}
                   placeholder={placeholder}
                   onBlur={field.onBlur}
                   isInvalid={!!meta.error && meta.touched}
                   name={field.name}
            />
        </EuiFormRow>
    )
}