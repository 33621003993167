import React from "react";
import {
    EuiFieldText,
    EuiHorizontalRule,
    EuiSpacer,
    EuiStep,
    EuiTextArea
} from '@elastic/eui';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../reducers";
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import {BasicInfo} from "../types/forms/basic_info";
import {updateBasicInfo} from "../reducers/basic_info";
import {EuiFormik} from "./myformik/form";
import {EuiFormikInputRow} from "./myformik/input_row";
import {EuiFormikSubmitButton} from "./myformik/button";


const schema = Yup.object({
    openapi: Yup.object({
        openapi: Yup.string().required("OpenAPI Version is required")
    }),
    info: Yup.object({
        version: Yup.string().required("API Version is required"),
        title: Yup.string().required("API Title is required"),
        termsOfService: Yup.string().url("API Terms of Service must be a valid URL"),
    }),
    license: Yup.object({
        url: Yup.string().url("License URL must be a valid URL"),
        name: Yup.string().when('url', {
            is: url => typeof url === "string" && url.length > 0,
            then: Yup.string().required("License Name is required"),
            otherwise: Yup.string().notRequired(),
        })
    }),
    contact: Yup.object({
        name: Yup.string().when(['url', 'email'], {
            is: (url, email) => (typeof url === "string" && url.length > 0) || (typeof email === "string" && email.length > 0),
            then: Yup.string().required("Contact Name is required"),
            otherwise: Yup.string().notRequired(),
        }),
        url: Yup.string().url("Contact URL must be a valid URL"),
        email: Yup.string().email("Contact Email must be a valid Email")
    })
})

export const BasicEditor: React.FC = () => {
    const initialValues: BasicInfo = useSelector((state: RootState) => state.BasicInfo)
    const dispatch = useDispatch();
    const history = useHistory();

    const submitForm = (values: BasicInfo) => {
        dispatch(updateBasicInfo(values))
        history.push("/dashboard")
    }

    return <Formik validationSchema={schema} initialValues={initialValues} onSubmit={submitForm}>
        {() =>
            <Form>
                <EuiFormik>
                    <EuiStep title="Basic API Info" step={1}>
                        <EuiSpacer size="s"/>
                        <EuiFormikInputRow label="OpenAPI Version" placeholder="Version" as={EuiFieldText}
                                           name="openapi.openapi"/>
                        <EuiHorizontalRule margin="xl"/>
                        <EuiFormikInputRow label="API Version" placeholder="Version" as={EuiFieldText}
                                           name="info.version"/>
                        <EuiFormikInputRow label="API Title" placeholder="My amazing API" as={EuiFieldText}
                                           name="info.title"/>
                        <EuiFormikInputRow label="API Description"
                                           placeholder="My awesome API does so many things..." as={EuiTextArea}
                                           name="info.description"/>
                        <EuiFormikInputRow label="API Terms of Service" placeholder="http://example.com/tos"
                                           as={EuiFieldText} name="info.termsOfService"/>
                    </EuiStep>
                    <EuiStep title="Contact Info" step={2}>
                        <EuiFormikInputRow label="Contact Name" placeholder="John Doe" as={EuiFieldText}
                                           name="contact.name"/>
                        <EuiFormikInputRow label="Contact URL" placeholder="http://example.com/contact"
                                           as={EuiFieldText} name="contact.url"/>
                        <EuiFormikInputRow label="Contact Email" placeholder="example@example.com" as={EuiFieldText}
                                           name="contact.email"/>
                    </EuiStep>
                    <EuiStep title="License and TOS" step={3}>
                        <EuiFormikInputRow label="License Name" placeholder="Apache 2.0" as={EuiFieldText}
                                           name="license.name"/>
                        <EuiFormikInputRow label="License URL"
                                           placeholder="https://www.apache.org/licenses/LICENSE-2.0.html"
                                           as={EuiFieldText} name="license.url"/>
                    </EuiStep>
                    <EuiSpacer size="xl"/>
                    <EuiFormikSubmitButton
                        fill
                        iconType="arrowRight"
                        iconSide="right"
                        type="submit">
                        Finish
                    </EuiFormikSubmitButton>
                </EuiFormik>
            </Form>
        }
    </Formik>
}
