import {Server} from "../types/openapi/server";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: Server[] = []

export const serversSlice = createSlice({
    name: "servers",
    initialState: initialState,
    reducers: {
        upsert: (state, action: PayloadAction<{
            server: Server;
            initial_server_url?: string;
        }>) => {
            // UPDATE
            if (action.payload.initial_server_url) {
                const index = state.findIndex(s => s.url === action.payload.initial_server_url)
                state[index] = action.payload.server
                return
            }

            // INSERT
            state.push(action.payload.server)
        },
        delete: (state, action: PayloadAction<Server>) => {
            const deleteIndex = state.findIndex(s => s.url === action.payload.url)
            state.splice(deleteIndex, 1)
        }
    },
})

export const {
    upsert: upsertServer,
    delete: deleteServer
} = serversSlice.actions





