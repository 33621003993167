import React, {Fragment, useState} from "react";
import {
    EuiBadge,
    EuiSuperSelect,
    EuiBadgeGroup,
    EuiButton,
    EuiButtonGroup,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel, EuiHealth, EuiText, EuiComboBox, EuiSpacer, EuiTreeView, EuiIcon, EuiToken
} from "@elastic/eui";
import {EuiButtonGroupOption} from "@elastic/eui/src/components/button/button_group/button_group";

export const BodyForm: React.FC = () => {

    const buttons: EuiButtonGroupOption[] = [
        {
            id: "add_response",
            label: "Response",
            iconType: "plusInCircleFilled",
        }
    ]

    const [id, setID] = useState("add_response")


    return (
        <Fragment>
            <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                    <div className="eui-displayInline">
                        {/*<EuiButton iconType="plusInCircleFilled" size="s">
                            Response
                        </EuiButton>*/}
                        <EuiButtonGroup idSelected={id} name="primary" options={buttons} color="primary"
                                        onChange={(id) => {
                                            console.log(id)
                                            setID(id)
                                        }}/>
                    </div>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiComboBox selectedOptions={[{"label": "200 OK", value: "200"}]} style={{width: 300}}
                                 placeholder="Select or add code" options={[
                        {
                            label: "Success", options: [
                                {"label": "200 OK", value: "200"},
                                {"label": "201 Created"},
                            ]
                        },
                        {
                            label: "Redirects", options: [
                                {"label": "301 Moved Permanently"},
                                {"label": "302 Found"},
                            ]
                        }
                    ]}/>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer/>
            <EuiText>
                OK
            </EuiText>
            <EuiSpacer/>
            <EuiButton iconType="plusInCircle">
                Headers
            </EuiButton>
            <EuiSpacer/>
            <EuiButton iconType="plusInCircle">
                Add Body
            </EuiButton>
            <EuiSpacer/>

            <EuiTreeView showExpansionArrows={true} items={[
                {
                    label: "object",
                    id: "object",
                    icon: <EuiToken iconType="tokenObject" style={{verticalAlign:"sub"}}/>,
                    isExpanded: true,
                    children: [
                        {
                            label: "hostname - string",

                            id: "hostname",
                            icon: <EuiToken iconType="tokenString" style={{verticalAlign: "sub"}}/>,
                        },
                        {
                            label: "hostname - string",

                            id: "hostname",
                            icon: <EuiIcon type="tokenString"/>
                        }
                    ]
                }
            ]} aria-label="Schema"/>




        </Fragment>
    )
}