import React, {Fragment} from "react";
import {
    EuiButton,
    EuiCodeBlock,
    EuiComboBox,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSpacer,
    EuiTitle,
    EuiSwitch,
    EuiTabbedContent,
} from '@elastic/eui';
import {Link, useHistory, useLocation} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import {Path, PathWrapper} from "../types/openapi/path";
import {useDispatch, useSelector} from "react-redux";
import {deletePath, upsertPath} from "../reducers/path";
import {EuiFormik} from "./myformik/form";
import {EuiFormikInputRow} from "./myformik/input_row";
import {EuiFormikSubmitButton} from "./myformik/button";
import {Operation} from "../types/openapi/operation";
import {RootState} from "../reducers";

export const PathForm: React.FC = () => {
    const location = useLocation<PathWrapper>()
    const history = useHistory()
    const globalTags: string[] = useSelector((state: RootState) => state.Tags).map(t => t.name)
    const initialPath: PathWrapper = location.state ? location.state : {
        route: "",
        path: {
            summary: "", description: ""
        }
    }
    const initialURL = initialPath.route
    const title = location.state ? "Change path" : "Add path"
    const schema = Yup.object({
        route: Yup.string().required("Path is required")
    })
    const dispatch = useDispatch()

    const handleSubmit = (path: PathWrapper) => {
        dispatch(upsertPath([path, initialURL]))
        history.push("/dashboard")
    }

    const handleDeletePath = (path: PathWrapper) => {
        dispatch(deletePath(path))

        // TODO: вынести в отдельные функции для более удобного контроля роутинга
        history.push("/dashboard")
    }

    return (
        <Formik initialValues={initialPath} onSubmit={handleSubmit} validationSchema={schema}>
            {({values: path, handleBlur, handleChange, setFieldValue}) =>
                <Form>
                    <EuiFormik>
                        <EuiTitle>
                            <EuiFlexGroup justifyContent="spaceBetween">
                                <EuiFlexItem grow={false}>
                                    <h2>{title}</h2>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <Link to="/dashboard">
                                        <EuiButton size="s">Back to dashboard</EuiButton>
                                    </Link>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiTitle>
                        <EuiSpacer size="s"/>

                        {/*TODO: on change change operation ids on operations OR before submit form set operation ids because of validation route will not be empty*/}
                        <EuiFormikInputRow label="Route" placeholder="/example" as={EuiFieldText} name="route"/>
                        <EuiFormikInputRow label="Summary" placeholder="Route summary" as={EuiFieldText}
                                           name="path.summary"/>
                        <EuiFormikInputRow label="Description" placeholder="Optional Description" as={EuiFieldText}
                                           name="path.description"/>

                        <EuiSpacer size="xl"/>


                        <EuiTabbedContent
                            tabs={["get", "post", "put", "patch", "delete", "options", "head", "trace"].map(method => {
                                const upperMethod = method.toUpperCase();
                                return {
                                    id: method,
                                    name: upperMethod,
                                    content: (
                                        <Fragment>
                                            <EuiSpacer/>
                                            {!path.path[method as keyof Path] && <EuiButton onClick={() => {
                                                let operationID = ""
                                                if (path.route) {
                                                    if (path.route === "/") {
                                                        operationID = `${method}-root`
                                                    } else {
                                                        const joined = path.route.replaceAll("/", "-")
                                                        operationID = `${method}${joined}`
                                                    }
                                                }


                                                setFieldValue(`path.${method}`, {
                                                    tags: [],
                                                    summary: "",
                                                    description: "",
                                                    externalDocs: {
                                                        description: "",
                                                        url: ""
                                                    },
                                                    operationId: operationID,
                                                    parameters: [],
                                                    requestBody: [],
                                                    responses: [],
                                                    deprecated: false,
                                                })
                                            }}>
                                                Add {upperMethod} endpoint
                                            </EuiButton> || <Fragment>
                                                <EuiFormikInputRow label="Summary"
                                                                   placeholder={`${upperMethod} endpoint`}
                                                                   as={EuiFieldText}
                                                                   name={`path.${method}.summary`}/>
                                                <EuiFormikInputRow label="Description"
                                                                   placeholder="Optional description"
                                                                   as={EuiFieldText}
                                                                   name={`path.${method}.description`}/>

                                                {/* TODO: global tags to suggestions */}
                                                {/* TODO: MAYBE EuiSuperSelect for tag description*/}
                                                <EuiFormRow label="Tags">
                                                    <EuiComboBox delimiter=","
                                                        /*TODO: add to global tags*/
                                                                 onCreateOption={((searchValue, options) => {
                                                                     const normalizedSearchValues = searchValue.trim().toLowerCase();
                                                                     if (!normalizedSearchValues) {
                                                                         return
                                                                     }

                                                                     if (options.findIndex(option => option.label.trim().toLowerCase() === normalizedSearchValues) === -1) {
                                                                         const op = path.path[method as keyof Path] as (Operation | undefined)
                                                                         op?.tags.push(searchValue)
                                                                         setFieldValue("path." + method + ".tags", op?.tags)
                                                                     }
                                                                 })} placeholder="Select one or more tags"
                                                                 isClearable={true}
                                                                 onChange={(options) => {
                                                                     const op = path.path[method as keyof Path] as (Operation | undefined)
                                                                     if (op) {
                                                                         const tags = options.map(o => o.label)
                                                                         setFieldValue(`path.${method}.tags`, tags)
                                                                     }
                                                                 }}
                                                                 options={(globalTags.concat((path.path[method as keyof Path] as (Operation | undefined))?.tags || [])).map((tag) => {
                                                                     return {
                                                                         label: tag,
                                                                     }
                                                                 })} sortMatchesBy={"startsWith"}
                                                                 selectedOptions={(path.path[method as keyof Path] as (Operation | undefined))?.tags.map((tag) => {
                                                                     return {
                                                                         label: tag,
                                                                     }
                                                                 })}/>
                                                </EuiFormRow>


                                                <EuiFormRow label="Deprecated">
                                                    <EuiSwitch label="Deprecated"
                                                               checked={!!(path.path[method as keyof Path] as Operation)?.deprecated}
                                                               onChange={() => {
                                                                   const op = path.path[method as keyof Path] as Operation;
                                                                   if (op) {
                                                                       setFieldValue(`path.${method}.deprecated`, !op.deprecated)
                                                                   }
                                                               }}/>
                                                </EuiFormRow>


                                            </Fragment>
                                            }
                                        </Fragment>
                                    )
                                }
                            })
                            }/>

                        {/*TODO: SPACE*/}
                        <EuiSpacer size="xl"/>

                        <EuiFormikSubmitButton fill type="submit">{title}</EuiFormikSubmitButton>
                        <EuiSpacer size="xxl"/>
                        <EuiButton color="danger" onClick={() => handleDeletePath(path)}>Delete path</EuiButton>

                        <EuiCodeBlock lang={"json"}>
                            {JSON.stringify(path, null, 2)}
                        </EuiCodeBlock>
                    </EuiFormik>
                </Form>
            }
        </Formik>
    )
}
